import { FormEvent, useEffect, useState } from "react";
import { Brand, Category, Kodawari, Product, ProductStatus, Tag } from "../../../types/typescript-axios";
import moment from "moment";
import { LeftLabel } from "../../molecules/LeftLabel";
import { Editor } from "@tinymce/tinymce-react";
import { ChooseBrandModal } from "../../shared/ChooseBrandModal";
import { ChooseTagsModal } from "../../shared/ChooseTagsModal";
import { ChooseKodawariModal } from "../../shared/ChooseKodawariModal";
import { ChooseCategoriesModal } from "../../shared/ChooseCategoriesModal";
import { toDateString } from "../../../utils/toDateString";
import { EditImage, ImageListWithInput } from "../../parts/ImageListWIthInput";

type CategoryKodawarisHash = {
  [categoryId: string]: {
    category: Category,
    kodawaris: Kodawari[]
  }
}

export type ProductFormData = {
  name: string
  productStatusId: ProductStatus
  descriptionDetail: string
  priceText: string
  price02: number
  priceUnit?: string | null
  releaseDate?: string
  productUrl: string
  affiliate1: string
  affiliate2: string
  affiliate3: string
  affiliate4: string
  applyDate: string
  note: string
  brandId: number
  tagIds?: number[]
  categoryIds?: number[]
  kodawariIds?: number[]
  productImages: EditImage[]
}

type ProductFormProps = {
  product?: Product
  submit: (data: ProductFormData) => void
}

export const ProductForm = ({product, submit}: ProductFormProps) => {
  const [name, setName] = useState<string>(product?.name || '')
  const [productStatusId, setProductStatusId] = useState<ProductStatus>(product?.productStatusId as ProductStatus || ProductStatus.NUMBER_2)
  const [descriptionDetail, setDescriptionDetail] = useState<string>(product?.descriptionDetail || '')
  const [priceText, setPriceText] = useState<string>(product?.priceText || '')
  const [price02, setPrice02] = useState<number>(product?.price02 || 0)
  const [priceUnit, setPriceUnit] = useState<string>(product?.priceUnit || '')
  const [releaseDate, setReleaseDate] = useState<string>(product?.releaseDate || '')
  const [productUrl, setProductUrl] = useState<string>(product?.productUrl || '')
  const [affiliate1, setAffiliate1] = useState<string>(product?.affiliate1 || '')
  const [affiliate2, setAffiliate2] = useState<string>(product?.affiliate2 || '')
  const [affiliate3, setAffiliate3] = useState<string>(product?.affiliate3 || '')
  const [affiliate4, setAffiliate4] = useState<string>(product?.affiliate4 || '')
  const [applyDate, setApplyDate] = useState<string>(product?.applyDate || '')
  const [note, setNote] = useState<string>(product?.note || '')
  const [productImages, setProductImages] = useState<EditImage[]>(product?.productImages || [])

  const [brand, setBrand] = useState<Brand | undefined>(product?.brand)
  const [tags, setTags] = useState<Tag[]>(product?.productTags?.map(pt => pt.tag) || [])
  const [kodawaris, setKodawaris] = useState<Kodawari[]>(product?.kodawariOptions?.map(o => o.kodawari) || [])
  const [categoryKodawariHash, setCategoryKodawariHash] = useState<CategoryKodawarisHash>()
  const [categories, setCategories] = useState<Category[]>(product?.productCategories?.map(pc => pc.category) || [])

  const [showBrandModal, setShowBrandModal] = useState<boolean>(false)
  const [showTagsModal, setShowTagsModal] = useState<boolean>(false)
  const [showKodawarisModal, setShowKodawarisModal] = useState<boolean>(false)
  const [showCategoriesModal, setShowCategoriesModal] = useState<boolean>(false)

  const [errorStatement, setErrorStatement] = useState<string>()

  useEffect(() => {
    const hash = kodawaris?.reduce((prev: CategoryKodawarisHash, cur: Kodawari) => {
      if (!prev[cur.category.id]) prev[cur.category.id] = {category: cur.category, kodawaris: []}
      prev[cur.category.id].kodawaris.push(cur)
      return prev
    }, {})
    setCategoryKodawariHash(hash)
  }, [kodawaris])

  function executeSubmit(e: FormEvent) {
    e.preventDefault()
    if (!brand) return setErrorStatement('ブランドを入力してください')

    submit({
      name, productStatusId, descriptionDetail,
      priceText, price02, productUrl,
      affiliate1, affiliate2, affiliate3, affiliate4, applyDate, note,
      brandId: brand?.id, tagIds: tags.map(t => t.id),
      categoryIds: categories?.map(c => c.id), kodawariIds: kodawaris?.map(k => k.id),
      productImages,
      ...(releaseDate && { releaseDate: moment(releaseDate).format('YYYY/MM/DD') }),
      priceUnit: priceUnit === '' ? null : priceUnit,
    })
  }

  return (
    <>
      <form onSubmit={executeSubmit}>
        <div className='flex justify-between'>
          <div className='flex-1'>
            {
              product?.id && (
                <LeftLabel title='商品ID'>{product?.id}</LeftLabel>
              )
            }
            <LeftLabel title='商品名' required={true}>
              <input required value={name}
                     onChange={(e) => setName(e.target.value)}
              />
            </LeftLabel>
            <LeftLabel title='商品画像'>
              <div className='flex flex-wrap gap-8'>
                <ImageListWithInput images={productImages} setImages={setProductImages}/>
              </div>
            </LeftLabel>
            <LeftLabel title='商品説明'>
              <Editor
                apiKey='lnbg8xh0pb9trmq30hhib72tkfsg2mezcc9gtc4w3rp75l4y'
                init={{
                  height: 500,
                  width: 580,
                  menubar: true,
                  language: 'ja',
                }}
                value={descriptionDetail || ''}
                onEditorChange={(content) => setDescriptionDetail(content)}
              />
            </LeftLabel>
            <LeftLabel title='基準価格' required={true}>
              <input value={price02} required type='number' onChange={(e) => setPrice02(+e.target.value)}/>円
            </LeftLabel>
            <LeftLabel title='価格・容量'>
              <textarea className='h-60' value={priceText} onChange={(e) => setPriceText(e.target.value)}/>
            </LeftLabel>
            <LeftLabel title='単価'>
              <input value={priceUnit} type='number' onChange={(e) => setPriceUnit(e.target.value)} min={0}/>円
            </LeftLabel>
            <LeftLabel title='発売日'>
              <input type='date' value={toDateString(releaseDate)}
                     onChange={(e) => setReleaseDate(e.target.value)}
              />
            </LeftLabel>
            <LeftLabel title='購入URL'>
              <input value={productUrl}
                     onChange={(e) => setProductUrl(e.target.value)}
              />
            </LeftLabel>
            <LeftLabel title='Amazon URL'>
              https:// <input value={affiliate1}
                              onChange={(e) => setAffiliate1(e.target.value)}
            />
            </LeftLabel>
            <LeftLabel title='Yahoo! ショッピング URL'>
              https:// <input value={affiliate2}
                              onChange={(e) => setAffiliate2(e.target.value)}
            />
            </LeftLabel>
            <LeftLabel title='楽天市場 URL'>
              https:// <input value={affiliate3}
                              onChange={(e) => setAffiliate3(e.target.value)}
            />
            </LeftLabel>
            <LeftLabel title='Qoo10 URL'>
              <input value={affiliate4}
                     onChange={(e) => setAffiliate4(e.target.value)}
              />
            </LeftLabel>
          </div>
          <div className='ml-4 pl-4 w-[400px] border-l border-l-gray-200'>
            <div className='mt-4'>
              <label className='font-bold text-sm'>ブランド</label>
              <div onClick={() => setShowBrandModal(true)} className='flex items-center hover:underline cursor-pointer'>
                {brand?.name}
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                  <path d="M0 0h24v24H0z" fill="none"/>
                  <path
                    d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
                </svg>
              </div>
            </div>
            <div className='mt-4'>
              <label className='flex items-center font-bold text-sm'>
                カテゴリ
                <svg onClick={() => setShowCategoriesModal(true)} className='cursor-pointer ml-4'
                     xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                     width="24px" fill="#000000">
                  <path d="M0 0h24v24H0z" fill="none"/>
                  <path
                    d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
                </svg>
              </label>
              <ul className='list-disc pl-[20px]'>
                {
                  categories?.map((category, index) => ((
                    <li key={index}>{category.categoryName}</li>
                  )))
                }
              </ul>
            </div>
            <div className='mt-4'>
              <label className='flex items-center font-bold text-sm'>
                タグ
                <svg onClick={() => setShowTagsModal(true)} className='cursor-pointer ml-4'
                     xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                     width="24px" fill="#000000">
                  <path d="M0 0h24v24H0z" fill="none"/>
                  <path
                    d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
                </svg>
              </label>
              <ul className='list-disc pl-[20px]'>
                {
                  tags?.map((tag, index) => ((
                    <li key={index}>{tag.name}</li>
                  )))
                }
              </ul>
            </div>
            <div className='mt-4'>
              <label className='flex items-center font-bold text-sm'>
                こだわり
                <svg onClick={() => setShowKodawarisModal(true)} className='cursor-pointer ml-4'
                     xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                     width="24px" fill="#000000">
                  <path d="M0 0h24v24H0z" fill="none"/>
                  <path
                    d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
                </svg>
              </label>
              {
                categoryKodawariHash && Object.keys(categoryKodawariHash).map((categoryId, index) => ((
                  <div className='ml-4 mt-2' key={index}>
                    <span
                      className='font-bold text-[12px]'>{categoryKodawariHash[categoryId].category.categoryName}</span>
                    <ul className='list-disc ml-6'>
                      {
                        categoryKodawariHash[categoryId].kodawaris.map((kodawari, index) => ((
                          <li key={index}>{kodawari.name}</li>
                        )))
                      }
                    </ul>
                  </div>
                )))
              }
              {/*<ul className='list-disc pl-[20px]'>*/}
              {/*  {*/}
              {/*    product?.kodawariOptions?.map((kodawariOption, index) => ((*/}
              {/*      <li key={index}>{kodawariOption.kodawari.name}</li>*/}
              {/*    )))*/}
              {/*  }*/}
              {/*</ul>*/}
            </div>
            <div>
              <label className='flex items-center font-bold text-sm'>メモ</label>
              <textarea className='w-[300px] h-[140px]' value={note} onChange={(e) => setNote(e.target.value)}/>
            </div>
            <div className='mt-4'>
              <label className='flex items-center font-bold text-sm'>登録日</label>
              <div>{product?.createDate && moment(product?.createDate).format('YYYY-MM-DD')}</div>
              <label className='flex items-center font-bold text-sm'>更新日</label>
              <div>{product?.createDate && moment(product?.updateDate).format('YYYY-MM-DD')}</div>
              <label className='flex items-center font-bold text-sm'>最終更新者</label>
              {/*  TODO: 最終更新者 */}
            </div>
          </div>
        </div>
        <LeftLabel title='公開日' required={true}>
          <input type='date' value={toDateString(applyDate)}
                 required
                 onChange={(e) => setApplyDate(e.target.value)}
          />
        </LeftLabel>
        <LeftLabel title='ステータス'>
          <select value={productStatusId} onChange={(e) => setProductStatusId(+e.target.value as ProductStatus)}>
            <option value={1}>公開</option>
            <option value={2}>非公開</option>
            <option value={3}>廃止</option>
          </select>
        </LeftLabel>
        {
          errorStatement && (
            <p className='py-32 text-red-500'>{ errorStatement }</p>
          )
        }
        <div className='flex justify-center items-center gap-x-3'>
          <a className="bg-white text-blue-400 border-2 border-blue-400 hover:text-white rounded-[3px] font-bold
                        w-[200px] h-[50px] flex justify-center items-center hover:bg-blue-400" href="#" onClick={() => window.history.back()}>一覧へ戻る</a>
          <button>登録</button>
        </div>
      </form>
      {
        showBrandModal &&
        <ChooseBrandModal
          choosed={(brand) => {
            setShowBrandModal(false)
            if (brand) setBrand(brand)
          }}
          cancel={() => setShowBrandModal(false)}
        />
      }
      {
        showTagsModal &&
        <ChooseTagsModal
          initialTags={tags || []}
          choosed={(tags) => {
            setShowTagsModal(false)
            setTags(tags)
          }}
          cancel={() => setShowTagsModal(false)}
        />
      }
      {
        showKodawarisModal &&
        <ChooseKodawariModal
          initialKodawaris={kodawaris || []}
          choosed={(kodawaris) => {
            setShowKodawarisModal(false)
            setKodawaris(kodawaris)
          }}
          cancel={() => setShowKodawarisModal(false)}
        />
      }
      {
        showCategoriesModal &&
        <ChooseCategoriesModal
          initialCategories={categories || []}
          choosed={(categories) => {
            setShowCategoriesModal(false)
            setCategories(categories)
          }}
          cancel={() => setShowCategoriesModal(false)}
        />
      }
    </>

  )
}
