import { SidebarLayout } from "../../components/layout/SidebarLayout";
import { Manager, ManagerAuthority, ManagerAuthorityEnum } from "../../types/typescript-axios/api";
import { FormEvent, useEffect, useState } from "react";
import { useManager } from "../../components/contexts/ManagerProvider";
import { Link, useNavigate, useParams } from "react-router-dom";
import { authorizedRequest } from "../../utils/api-request";

export const EditManager = () => {
  const {id} = useParams()
  const manager = useManager()
  const navigate = useNavigate()
  const [targetManager, setTargetManager] = useState<Manager>()

  useEffect(() => {
    if (!id || !manager) return

    async function fetchManager() {
      const response = await authorizedRequest(manager, 'ShowManager', {id: Number(id)})
      if (response.status === 200) {
        setTargetManager(response.data.manager)
      }
    }

    fetchManager()
  }, [id, manager])

  const submit = async (e: FormEvent) => {
    e.preventDefault()
    if (!id || !targetManager) return

    const {email, authority, personalDataFlg} = targetManager

    await authorizedRequest(manager, 'UpdateManager', {
      id: Number(id),
      ...(email && {email}),
      ...(authority && {authority}),
      personalDataFlg: Boolean(personalDataFlg),
    })
    window.location.reload()
  }

  return (
    <SidebarLayout>
      <div className='py-4 px-4'>
        <h1>管理者編集</h1>

        {
          targetManager && (
            <form className='w-fit' onSubmit={submit}>
              <div>
                <label className='inline-block w-[240px]'>メールアドレス</label>
                <input
                  type='email'
                  value={targetManager.email}
                  onChange={(e) => setTargetManager({...targetManager, email: e.target.value})}
                />
              </div>
              <div className='mt-8'>
                <label className='inline-block w-[240px]'>権限</label>
                <select
                  value={targetManager.authority}
                  onChange={(e) =>
                    setTargetManager({
                      ...targetManager,
                      authority: e.target.value === ManagerAuthority.Staff ? ManagerAuthority.Staff : ManagerAuthority.Admin
                    })
                  }
                >
                  <option value={ManagerAuthorityEnum.Staff}>スタッフ</option>
                  <option value={ManagerAuthorityEnum.Admin}>管理者</option>
                </select>
              </div>

              <div>
                <label className='inline-block w-[240px]'>個人情報取得権限</label>
                <input
                  id='is-personal'
                  type='radio'
                  className='w-[24px]'
                  checked={targetManager.personalDataFlg}
                  onChange={() => setTargetManager({
                    ...targetManager,
                    personalDataFlg: true
                  })}
                />
                <label htmlFor='is-Personal'>
                  あり
                </label>
                <span className='inline-block w-[24px]' />
                <input
                  id='not-personal'
                  type='radio'
                  className='w-[24px]'
                  checked={!targetManager.personalDataFlg}
                  onChange={() => setTargetManager({
                    ...targetManager,
                    personalDataFlg: false
                  })}
                />
                <label htmlFor='not-Personal'>
                  なし
                </label>
              </div>

              <div className='mt-24 flex justify-center gap-x-16'>
                <Link to='/managers/list'>
                  <button type='button' className='gray-btn'>一覧へ</button>
                </Link>
                <button>更新</button>
              </div>
            </form>
          )
        }
      </div>
    </SidebarLayout>
  )
}
